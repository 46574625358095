import './App.css';

import WhiteboardWrapper from './WhiteboardWrapper';

function App() {
  return (
    <div className="App">
      <WhiteboardWrapper />
    </div>
  );
}

export default App;
