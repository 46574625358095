import { Touchable } from "@kalyzee/kast-web-sdk/web-components";

export interface ErrorInterface {
  error: string,
  setError: (error: string) => void,
}

export const Error = ({
  error,
  setError,
}: ErrorInterface) => {
  const renderContent = () => {
    return (
      <div style={{
        width: 'min(300px, 80vw)',
        display: 'flex',
        flexDirection: 'column',
        gap: '1em',
        padding: '0.5em',
        borderRadius: '0.25em',
        fontWeight: 500,
        color: 'white',
        backdropFilter: 'blur(2px) brightness(80%)',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <div style={{
            border: '1px solid gray',
            padding: '0.25em 0.5em 0.25em 0.5em',
            borderRadius: '0.25em',
            opacity: 0.8
          }}>Error</div>
          <Touchable 
            onPressOut={() => setError('')}
            style={{
              border: '1px solid gray',
              padding: '0.25em 0.5em 0.25em 0.5em',
              borderRadius: '0.25em',
              opacity: 0.8
            }}
          >
            Close
          </Touchable>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'left',
        }}>
          {error}
        </div>
      </div>
    );
  };

  if (!error) return null;

  return (
    <div style={{
      position: 'absolute',
      top: '80%',
      left: '50%',
      transform: 'translateX(-50%)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      zIndex: 15,
    }}>
      {renderContent()}
    </div>
  );    
};