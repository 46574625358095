import { Input, QRCode, SearchInput, Touchable, useOuterClickManyTargets } from "@kalyzee/kast-web-sdk/web-components";
import React, { useEffect, useRef, useState } from "react";
import { ShareData } from "./types";
import { generateSharingUrl, setClipboard } from "./utils";

import { ReactComponent as ClipboardIcon } from './assets/svg/clipboard.svg';
import { ShareButton } from "./ShareButton";

export interface ShareProps {
  loading: boolean,
  shareData: ShareData | undefined,
  onShare: () => void,
  onStopShare: () => void,
  display: boolean,
  setDisplay: (display: boolean) => void,
}

export const Share = ({
  loading,
  shareData,
  onShare,
  onStopShare,
  display,
  setDisplay,
}: ShareProps) => {
  const buttonRef = useRef(null);
  const inputRef = useRef(null);

  const fontSize = 'clamp(12px, min(1.5vw, 1.5vh), 18px)';

  useOuterClickManyTargets(
    [buttonRef, inputRef],
    () => setDisplay(false),
  );

  const handleButtonPress = async () => {
    if (loading) return;
    if (shareData) {
      onStopShare();
    } else {
      onShare();
    }
  }

  const renderQRCode = () => {
    if (!shareData) return null;
    return (
      <QRCode 
        text={generateSharingUrl(shareData)} 
        options={{ margin: 1 }} 
        style={{ width: 'min(40vw, 40vh)', height: 'min(40vw, 40vh)' }} 
      />
    );
  }

  const renderShareButton = () => {
    return (
      <div ref={buttonRef} style={{ fontSize }}>
        <ShareButton
          loading={loading} 
          shareData={shareData} 
          onClick={handleButtonPress} 
        />
      </div>
    );
  }

  const renderLinkInput = () => {
    if (!shareData) return null;
    return (
      <div 
        ref={inputRef}
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          gap: '1em',
          alignItems: 'center',
          fontSize,
        }}
      >
        <Input 
          inputStyle={{ 
            fontSize: '2em',
            borderRadius: '0.25em',
            backgroundColor: 'white',
            border: 'unset',
            padding: '0.2em',
            color: 'lightgreen'
          }}
          disabled
          value={generateSharingUrl(shareData)}
        />
        <Touchable 
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            borderRadius: '0.5em',
            color: 'white',
            backgroundColor: 'lightgreen',
            padding: '0.7em',
            width: '2em'
          }}
          additionalStylePressed={{
            backgroundColor: 'red',
          }}
          onPressOut={() => {
            setClipboard(generateSharingUrl(shareData))
          }}
        >
          <ClipboardIcon />
        </Touchable>
      </div>
    );
  }

  if (!display) return null;
  
  return (
    <div style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      zIndex: 10,
      backdropFilter: 'blur(10px) brightness(90%)',
      WebkitBackdropFilter: 'blur(10px) brightness(90%)',
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: 'min(80%, 800px)',
        height: '100%',
      }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '2em',
          }}>
            {renderQRCode()}
            {renderShareButton()}
            {renderLinkInput()}
          </div>
      </div>
    </div>
  );
};
