import { ShareData } from "./types";

export const generateSharingUrl = (sharingData: ShareData): string => {
    const url = new URL(window.location.href);
    url.searchParams.delete('id');
    url.searchParams.append('id', sharingData.id);
    return url.href;
}

export const getParamFromCurrentUrl = (param: string): string | null => {
    const url = new URL(window.location.href);
    return url.searchParams.get(param); 
}

export const removeSharingIdFromCurrentUrl = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete('id');
    window.history.pushState(null, '', url.toString());
}

export const addSharingIdToCurrentUrl = (id: string) => {
    const url = new URL(window.location.href);
    url.searchParams.set('id', id);
    window.history.pushState(null, '', url.toString());
}

export const setClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
}