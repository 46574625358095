import { ReactComponent as LinkIcon } from './assets/svg/link.svg';
import { ReactComponent as UnlinkIcon } from './assets/svg/unlink.svg';
import { ReactComponent as LoadingIcon } from './assets/svg/loading.svg';
import React from 'react';
import { Touchable } from '@kalyzee/kast-web-sdk/web-components';
import { ShareData } from './types';

const StatusData = {
  loading: {
    text: 'Loading',
    icon: LoadingIcon,
    color: 'gray',
  },
  sharing: {
    text: 'Leave sharing',
    icon: LinkIcon,
    color: 'red',
  },
  notSharing: {
    text: 'Start sharing',
    icon: UnlinkIcon,
    color: 'lightgreen',
  }
};

export interface ShareButtonProps {
  loading: boolean,
  shareData?: ShareData,
  onClick: () => void,
}

export const ShareButton = ({
  loading,
  shareData,
  onClick,
}: ShareButtonProps) => {
  let statusData;
  if (loading) statusData = StatusData.loading; 
  else if (shareData) statusData = StatusData.sharing;
  else statusData = StatusData.notSharing;
  return (
    <Touchable 
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0.5em',
        borderRadius: '0.5em',
        color: 'white',
        fontWeight: 500,
        backgroundColor: statusData.color,
        fontSize: '1.5em',
        gap: '0.5em'
      }}
      onPressOut={onClick}
    >
      {React.createElement(statusData.icon, { style: { width: '1.5em' } })}
      <div style={{
        whiteSpace: 'nowrap'
      }}>
        {statusData.text} 
      </div>
    </Touchable>
  );
};
